import Vue from "vue"
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"
import Loading from '@/components/widgets/Loading.vue'

export default {
  components: {
    Loading
  },
  mixins: [validationMixin],
  validations: {
    company_name: { required },
    phone_number: { required },
    address: { required }
  },
  data() {
    return {
      loading: false,
      inputType: 'file',
      fileError: '',
      file: null,
      company_image: null,
      company_name: null,
      tax_number: null,
      address: null,
      phone_number: null,
      fax_number: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.onInitData()
    })
  },
  methods: { 
    handleInfoEvent(event) {
      if (event.status) {
        this.info.state = false
      }
    },
    onupdate(id) {
      this.create.Id = id
      this.create.mode = 'update'
      this.create.state = !this.create.state
    },
    onFileHandler(e) {
      this.$refs.fileInput.click()
    },
    onResetFileInput() {
      this.inputType = 'text'      
      this.$nextTick(() => {
        this.fileError = ''
        this.file = null
        this.inputType = 'file'    
      })
    },
    onFileChange(e) {
      this.fileError = ''
      const selectedFile = e.target.files[0]
      // File type validation (accept only image files)
      const allowedFileTypes = ['image/jpeg', 'image/png', 'image/jpg']
      if (!allowedFileTypes.includes(selectedFile.type)) {
        this.fileError = 'ไฟล์รูปภาพไม่ถูกต้อง. กรุณาเลิอกไฟล์รูปภาพ.'
        return;
      } 
      
      this.onFIletoBase64(selectedFile).then(file => {
        this.file = file
      }).catch(error => {
        this.onExceptionHandler(error.message)
      })
    },
    onFIletoBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    async onInitData() {
      try {
        this.busy = true
        this.loading = true
        const result = await this.$store.dispatch('Setting/get', { root: true })
        setTimeout(() => {
          this.busy = false
          this.loading = false
          if (result) {
            if (result.data.company_image) {
              result.data.company_image = `${Vue.prototype.$host}/storage/` + result.data.company_image
              this.file = result.data.company_image
            }

            this.company_name = result.data?.company_name
            this.tax_number = result.data?.tax_number
            this.address = result.data?.address
            this.phone_number = result.data?.phone_number
            this.fax_number = result.data?.fax_number && result.data?.fax_number !== 'null'? result.data?.fax_number: ''
          }
        }, 500)
      } catch (error) {
        this.busy = false
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async onSubmitForm() {
      try {
        this.loading = true
        this.$v.$touch()
        if (this.$v.$anyError) {
          this.loading = false
          return
        }
        const formData = new FormData()
        formData.append('company_name', this.company_name)
        formData.append('tax_number', this.tax_number)
        formData.append('address', this.address)
        formData.append('phone_number', this.phone_number)
        formData.append('fax_number', this.fax_number)

        await this.$axios.post(this.$host + "/api/v1/setting/company" + (this.mode === 'update' ? '/' + this.id  : ''), 
        formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })

        this.loading = false
        this.$swal({
          width: "29rem",
          padding: '1.825em',
          html: '<div class="d-flex flex-row align-items-center">' +
            '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
            '<div class="d-flex flex-column">'+
            '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
            '<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>'+
            '</div>'+
          '</div>',
            allowOutsideClick: false,
            focusConfirm: false,
            customClass: {
              popup: 'swal2-custom-rounded',
              closeButton: 'text-3xl swal2-custom-close',
              actions: 'justify-content-end',
              contant: 'd-flex flex-row justify-content-around px-2',
              title: 'd-none',
              confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
              cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
            },
            showCancelButton: false,
            confirmButtonText: "ตกลง",
            cancelButtonText: "ยกเลิก",
            showCloseButton: true,
        }).then(result => { })
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }      
    }
  }
}
